import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import * as PR from "../../prime-modules/index";
import { getAffiliateUsersList, affiliateActivateAPI, affiliateDeactivateAPI } from "../../services/api";
import AdminFooter from "../layout/admin-footer";
import AdminHeader from "../layout/admin-header";
import { useSelector, useDispatch } from 'react-redux';
import { globalConfig } from "../../GlobalConfig";
import { useFormik } from "formik";
import * as Yup from 'yup';
import * as utils from '../../utils';
import { QRCode } from 'react-qrcode-logo';

const Affiliates = () => {
    const dispatch = useDispatch();
    const qrRef = useRef({});
    const adminData = useSelector(state => state.adminAuth.adminSessionData);
    const headers = useMemo(() => {
        return { sessionid: adminData.sessionId };
    }, [adminData.sessionId]);
    const appName = process.env?.REACT_APP_NAME;
    const affURL = process.env.REACT_APP_AFF_URL

    const [sortOrder, setSortOrder] = useState(-1);
    const [sortField, setSortField] = useState("name");
    const [sortKey, setSortKey] = useState("-name");

    const [users, setUsers] = useState([]);
    const toast = useRef();
    const [loading, setLoading] = useState(true);

    const [pageCount] = useState(globalConfig.pageCount);
    const [page, setPage] = useState(1);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(pageCount + 1);
    const [last, setLast] = useState(true);
    const [paging, setPaging] = useState(false);
    const [affiliateName, setAffiliateName] = useState('');
    const [affiliateEmail, setAffiliateEmail] = useState('');
    const [affiliatePhone, setAffiliatePhone] = useState('');
    const [affiliateStatus, setAffiliateStatus] = useState([]);
    const [affiliatesData, setAffiliatesData] = useState(true);

    const [isDialogVisible, setDialogVisible] = useState(false);
    const [selectedAffiliateId, setSelectedAffiliateId] = useState(null);
    const [isDeactivationDialogVisible, setDeactivationDialogVisible] = useState(false)
    const [durationValue, setDurationValue] = useState('');
    const [discountValue, setDiscountValue] = useState('');
    const [discountError, setDiscountError] = useState('');
    const [affiliateNameActivate, setAffiliateNameActivate] = useState('');

    const handleCheckboxChange = (rowData) => {
        const isActive = rowData?.status === 'ACTIVE';
        setSelectedAffiliateId(rowData?.id);
        setDurationValue(rowData?.revenueRecognitionPeriodInDays)
        setDiscountValue(rowData?.permanentDiscountPercentage)
        setAffiliateNameActivate(rowData?.name)
        if (isActive) {
            setDeactivationDialogVisible(true);
        } else {
            setDialogVisible(true);
        }
    };

    const handleDeactivate = () => {
        const response = (response) => {
            if (response.result === "SUCCESS") {
                toast.current.show({
                    severity: "success",
                    summary: "Success",
                    detail: "Affiliate has been successfully deactivated.",
                });
                getAllAffiliateUsers()
            } else if (response.result === "FAILED" && response.error) {
                const error = response.error;
                toast.current?.show({
                    severity: error.severity,
                    summary: "Error",
                    detail: error.errorMsg ? error.errorMsg : error.summary,
                });
            }
        };
        affiliateDeactivateAPI(selectedAffiliateId, dispatch, headers, response);
        setDeactivationDialogVisible(false);
    };

    const handleActivateSubmit = async () => {
        if(discountValue > 100) {
            setDiscountError ("Value must not exceed 100")
            return
        } else {
            setDiscountError("")
        }
        const obj = {
            "revenueRecognitionPeriodInDays": durationValue,
            "permanentDiscountPercentage": discountValue
        };
        const response = (response) => {
            if (response.result === "SUCCESS") {
                toast.current.show({
                    severity: "success",
                    summary: "Success",
                    detail: "Affiliate has been successfully activated.",
                });
                getAllAffiliateUsers()
                setDialogVisible(false);
                setDurationValue('')
                setDiscountValue('')
                setDiscountError('')
            } else if (response.result === "FAILED" && response.error) {
                const error = response.error;
                toast.current?.show({
                    severity: error.severity,
                    summary: "Error",
                    detail: error.errorMsg ? error.errorMsg : error.summary,
                });
            }
        };
        affiliateActivateAPI(obj, dispatch, headers, selectedAffiliateId, response);
    }

    const onSortChange = (value) => {
        if (value.indexOf("!") === 0) {
            setSortOrder(-1);
            setSortField(value.substring(1, value.length));
            setSortKey(value);
        } else {
            setSortOrder(1);
            setSortField(value);
            setSortKey(value);
        }
        setPage(1);
        setOffset(0);
        setLimit(pageCount + 1);
    };

    const getAllAffiliateUsers = useCallback(async () => {
        if (affiliatesData) {
            setLoading(true);
            const sortOrderVal = (sortOrder === 1) ? "asc" : "desc";
            const obj = {
                offset: offset,
                limit: limit,
                sortField: sortField,
                sortOrder: sortOrderVal,
                name: affiliateName,
                email: affiliateEmail,
                phone: affiliatePhone,
                status: affiliateStatus.join(','),
            };
            getAffiliateUsersList(obj, headers, dispatch, response => {
                if (response.result === 'SUCCESS') {
                    const list = response.data;        
                    const result = list !== null ? list.slice(0, pageCount) : [];
                    if (result.length > 0) {
                        setLast(list.length <= pageCount);
                        setUsers(result);
                        setPaging(true);
                    } else {
                        setUsers([]);
                        setLast(true);
                        setPaging(false);
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'No records found' });
                    }
                } else {
                    setUsers([]);
                    setLast(true);
                    setPaging(false);
                    const error = response.error;
                    toast.current.show({ severity: error.severity, summary: 'Error', detail: (error.errorMsg) ? error.errorMsg : error.summary })
                }
                setLoading(false);
            })
        }
    }, [sortOrder, offset, limit, sortField, affiliateName, affiliateEmail, affiliatePhone, headers, dispatch, pageCount, affiliatesData, affiliateStatus])

    useEffect(() => {
        getAllAffiliateUsers()
    }, [getAllAffiliateUsers])

    const paginate = (currentPage) => {
        setPaging(false);
        setPage(currentPage);
        const offsetVal = (currentPage - 1) * pageCount;
        const limitVal = pageCount + 1;
        setOffset(offsetVal);
        setLimit(limitVal);
    };   

    const getUrl = (values) => {
        const baseUrl = (appName === 'data2go' || appName === 'esimcrew') ? `${affURL}&referrer=afid=` : `${affURL}?afid=`;
        return `${baseUrl}${values}`;
    };

    const handleCopyClipboard = (values) => {
        const url = getUrl(values);
        navigator.clipboard.writeText(url)
        toast.current.show({
            severity: 'success',
            summary: 'Copied',
            detail: `Affiliate Link copied to clipboard!`,
            life: 3000,
        });
    };

    const downloadQRCode = async (id) => {
        const canvas = qrRef.current[id].querySelector('canvas');
        const image = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = image;
        link.download = 'affiliate_qrcode.png';
        link.click();
       /*  const affiliateLink = getUrl(affiliateLinks);
        try {
          const qrCodeDataUrl = await QRCode.toDataURL(affiliateLink);
          const link = document.createElement('a');
          link.href = qrCodeDataUrl;
          link.download = 'affiliate_qr_code.png'; // Filename for the downloaded QR code
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (error) {
           toast.current.show({ severity: 'error', summary: 'Error', detail: "Failed to generate QR code" });
        } */
      };

    const itemTemplate = (rowData) => {
        return (
            <div className="col-12 border-0 custom-bg">
                <div className="custom-table-body">
                    <div className="table-grid">
                        <ul className="col-ul">
                            <li className="affiliateUserStatus">
                                <PR.InputSwitch checked={rowData.status === 'ACTIVE'} onChange={() => handleCheckboxChange(rowData)} />
                            </li>
                            <li className="affiliateUserName">{rowData.name}</li>
                            <li className="affiliateUserEmail">{rowData.email}</li>
                            <li className="affiliateUserPhone">{rowData?.phone ? rowData?.phone?.callingCode + " " + rowData?.phone?.localPhoneNumber : '-'} </li>
                            <li className="affiliateId">{rowData.afId}</li>
                            <li className="affiliateId discount">{rowData?.permanentDiscountPercentage != null ? rowData.permanentDiscountPercentage === 0  ? rowData.permanentDiscountPercentage : `${rowData?.permanentDiscountPercentage}%` : "-"}</li>
                            <li className="actionsColumn">
                                {<>
                                        <div ref={(el) => qrRef.current[rowData.id] = el} style={{display: 'none'}}>
                                            <QRCode
                                                id={rowData.afId}
                                                value={getUrl(rowData.afId)}
                                                size={500}
                                                logoImage={`brands/${appName}-icon.png`}
                                                logoWidth={50}
                                                logoHeight={50}
                                            />
                                        </div>
                                        <i
                                            className="pi pi-download"
                                            title="Download QR Code"
                                            style={{ color: 'red', marginRight: '0.7rem', cursor: 'pointer' }}
                                            onClick={() => downloadQRCode(rowData.id)} 
                                        />
                                </>
                                }
                                {<i
                                    className="pi pi-copy"
                                    title="Copy Affiliate Link"
                                    style={{ color: 'red', cursor: 'pointer' }}
                                    onClick={() => handleCopyClipboard(rowData.afId)}
                                />
                                }
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    };

    const affiliatesDataHeader = (field, label, selector) => {
        return (
            (sortField !== field) ?
                <>
                    <li onClick={(e) => onSortChange(field)} className={selector}><span>{label}<i className="pi pi-sort-alt"></i></span> </li>
                </> :
                <>
                    {(sortKey === field) ?
                        <>
                            <li onClick={(e) => onSortChange('!' + field)} className={selector}><span className="selectedList">{label}<i className="pi pi-sort-amount-up-alt"></i></span> </li>
                        </> :
                        <li onClick={(e) => onSortChange(field)} className={selector}><span className="selectedList">{label}<i className="pi pi-sort-amount-down"></i></span> </li>
                    }
                </>
        );
    }

    const [initialValues] = React.useState({
        searchAffiliateName: affiliateName,
        searchAffiliateEmail: affiliateEmail,
        searchAffiliatePhone: affiliatePhone,
        searchAffiliateStatus: affiliateStatus
    })

    const validationSchema = Yup.object().shape({
        searchAffiliateName: Yup.string().trim(),
        searchAffiliateEmail: Yup.string().trim().email('Invalid email'),
        searchAffiliatePhone: Yup.string().trim(),
    })

    const resetUserForm = () => {
        formik.resetForm();
        setAffiliateName('');
        setAffiliateEmail('');
        setAffiliatePhone('');
        setAffiliateStatus([]);
        setPage(1);
        setOffset(0);
        setLimit(pageCount + 1);
    }

    const handleAffiliateSelectedStatus = (val) => {
        setAffiliateStatus(val);
        setAffiliatesData(false);
    }


    const handleSubmit = (formData) => {
        setAffiliatesData(true);
        setAffiliateName(encodeURIComponent(trimFormData(formData.searchAffiliateName)));
        setAffiliateEmail(encodeURIComponent(trimFormData(formData.searchAffiliateEmail)));
        setAffiliatePhone(encodeURIComponent(trimFormData(formData.searchAffiliatePhone)));
        setPage(1);
        setOffset(0);
        setLimit(pageCount + 1);
    }

    const trimFormData = (val) => {
        return val.trim();
    }

    const discountChange = (e) => {
        const newValue = e.value;
        setDiscountValue(newValue);  
    }

    useEffect(() => {
        if (discountValue <= 100) {
            setDiscountError("");
        } else {
            setDiscountError("Value must not exceed 100");
        }
    }, [discountValue]);

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: handleSubmit
    })

    const affiliateStatusOptions = [
        { label: "Active", value: "active" },
        { label: "Inactive", value: "inactive" },
    ];

    return (
        <>
            <div className="main">
                <div className="layout-sidebar">
                    <AdminHeader />
                </div>
                <div className="layout-content-wrapper">
                    <section className="admin-affiliateslist-section">
                        <div className="grid grid-nogutter">
                            <div className="col-12">
                                <div className="heading-sec">
                                    <h2>Affiliates</h2>
                                    <div className="filter-right mt-5">
                                        <form autoComplete="off" onSubmit={formik.handleSubmit}>
                                            <div className='flex align-items-center'>
                                                <div className="users-search-input">
                                                    <span>
                                                        <PR.InputText placeholder="Affiliate Name" name="searchAffiliateName" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.searchAffiliateName} autoComplete="off" />
                                                    </span>
                                                    {formik.errors.searchAffiliateName && formik.touched.searchAffiliateName
                                                        ? <div className='error-message'>{formik.errors.searchAffiliateName}</div>
                                                        : ''
                                                    }
                                                </div>


                                                <div className="users-search-input">
                                                    <span>
                                                        <PR.InputText placeholder="Email ID" name="searchAffiliateEmail" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.searchAffiliateEmail} autoComplete="off" />
                                                    </span>
                                                    {formik.errors.searchAffiliateEmail && formik.touched.searchAffiliateEmail
                                                        ? <div className='error-message'>{formik.errors.searchAffiliateEmail}</div>
                                                        : ''
                                                    }
                                                </div>

                                                <div className="users-search-input">
                                                    <span>
                                                        <PR.InputText placeholder="Phone Number" name="searchAffiliatePhone"
                                                            onChange={(e) => utils.handlePhoneChange(e, formik, 'searchAffiliatePhone')}
                                                            onBlur={formik.handleBlur} value={formik.values.searchAffiliatePhone} autoComplete="off" />
                                                    </span>
                                                    {formik.errors.searchAffiliatePhone && formik.touched.searchAffiliatePhone
                                                        ? <div className='error-message'>{formik.errors.searchAffiliatePhone}</div>
                                                        : ''
                                                    }
                                                </div>

                                                <div className="users-search-input">
                                                    <span>
                                                        <PR.MultiSelect filter resetFilterOnHide value={affiliateStatus} options={affiliateStatusOptions} onChange={(e) => handleAffiliateSelectedStatus(e.value)} name="searchOrderStatus" optionLabel="label" placeholder="Status" maxSelectedLabels={1} />
                                                    </span>
                                                    {formik.errors.searchOrderStatus && formik.touched.searchOrderStatus
                                                        ? <div className='error-message'>{formik.errors.searchOrderStatus}</div>
                                                        : ''
                                                    }
                                                </div>

                                                <div className="users-search-input">
                                                    <span>
                                                        <PR.Button label="Search" type='submit' className="searchBtn" />
                                                        <PR.Button label="Reset" type='reset' className="resetBtn" onClick={resetUserForm} />
                                                    </span>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="users-data-table card">
                            <div className="card">
                                <div className="custom-table">
                                    <div className="custom-table-header">
                                        <div className="table-grid">
                                            <ul className="col-ul sorting-li">
                                                {affiliatesDataHeader('status', 'Status', 'affiliateUserStatus')}
                                                {affiliatesDataHeader('name', 'Affiliate Name', 'affiliateUserName')}
                                                {affiliatesDataHeader('email', 'Email ID', 'affiliateUserEmail')}
                                                {affiliatesDataHeader('phone', 'Phone Number', 'affiliateUserPhone')}
                                                <li className="affiliateId">Affiliate ID</li>
                                                <li className="affiliateId discount">Discount Value</li>
                                                <li className="actionsColumn">Actions</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <PR.DataView
                                        loading={loading}
                                        value={users}
                                        itemTemplate={itemTemplate}
                                    />
                                </div>
                            </div>

                        </div>
                        <PR.Dialog
                            visible={isDialogVisible}
                            dismissableMask={true}
                            draggable={false}
                            resizable={false}
                            header="Activate Affiliate"
                            onHide={() =>  { setDialogVisible(false); setDiscountError("") }}
                            className="affiliate-dialog"
                            maskClassName="affiliate-dialog-mask"
                        >
                            <div>
                                <p>By clicking on confirm, you are activating the <br/> <strong>{affiliateNameActivate}</strong> account.</p>
                                <div style={{ marginTop: '1rem' }}>
                                    <label htmlFor="revenue-recognition">Set revenue recognition period</label>
                                    <PR.InputNumber
                                        value={durationValue}
                                        onValueChange={(e) => setDurationValue(e.value)}
                                        min={0}
                                        inputId="revenue-recognition"
                                        className="days-input"
                                    />
                                    <span style={{ marginLeft: '0.5rem' }}>days</span>
                                </div>
                                <div style={{ marginTop: '1rem' }}>
                                    <label htmlFor="revenue-recognition">Discount value</label>
                                    <PR.InputNumber
                                        value={discountValue}
                                        onValueChange={discountChange}
                                        min={0}
                                        mode="decimal"
                                        minFractionDigits={0}
                                        maxFractionDigits={0}
                                        inputId="revenue-recognition"
                                        className="percentage-input"
                                    />
                                    <span style={{ marginLeft: '0.6rem' }}>%</span>
                                </div>
                                {discountError !== "" ? <small className="p-error">{discountError}</small> : null}
                                <div className="dialog-footer buttons-sections flex gap-3 align-items-center justify-content-end">
                                    <PR.Button onClick={() => setDialogVisible(false)} label="Cancel" className="confirm-button reset-btn min-width"/>
                                    <PR.Button onClick={handleActivateSubmit} label="Confirm" className="confirm-button min-width"/>
                                </div>
                            </div>
                        </PR.Dialog>

                        <PR.Dialog
                            visible={isDeactivationDialogVisible}
                            dismissableMask={true}
                            draggable={false}
                            resizable={false}
                            header="Confirm Deactivation"
                            onHide={() => setDeactivationDialogVisible(false)}
                            className="affiliate-dialog"
                            maskClassName="affiliate-dialog-mask"
                        >
                            <p>Are you sure you want to deactivate this affiliate?</p>
                            <div className="dialog-footer buttons-sections flex gap-3 align-items-center justify-content-end">
                                <PR.Button onClick={() => setDeactivationDialogVisible(false)} label="No" className="confirm-button  reset-btn min-width"/>
                                <PR.Button onClick={handleDeactivate} label="Yes" className="confirm-button min-width"/>
                            </div>
                        </PR.Dialog>
                        {users.length > 0 ? (
                            <div className="pagination">
                                <button
                                    type="button"
                                    onClick={() => paginate(page - 1)}
                                    className={paging ? page <= 1 ? "disabled" : "pagination-button" : "disabled"}
                                >
                                    {globalConfig.pagination_Previous}
                                </button>
                                <span className="active"> {page} </span>
                                <button
                                    type="button"
                                    onClick={() => paginate(page + 1)}
                                    className={paging ? last ? "disabled" : "pagination-button" : "disabled"}
                                >
                                    {globalConfig.pagination_Next}
                                </button>
                            </div>
                        ) : (
                            <></>
                        )}
                        <PR.Toast ref={toast} position='top-right' />
                    </section>
                    <AdminFooter />
                </div>
            </div>
        </>
    );
};

export default Affiliates;